import { useFormatMessage } from '../../helpers/intl'

export const useReportTranslation = () => {
  const buttonTitle = useFormatMessage('dashboard.reports.modal.title.generateReport', 'Generate Report')
  const erroredTrxsBtnTitle = useFormatMessage(
    'dashboard.reports.modal.title.generateErroredReport',
    'Generate Errored Transactions Report',
  )
  const trxText = useFormatMessage('dashboard.reports.table.row.type.transaction', 'Transactions')
  const erroredTrxText = useFormatMessage('dashboard.reports.table.row.type.erroredTransaction', 'Errored Transactions')
  const todayText = useFormatMessage('dashboard.calendar.today', 'Today')
  const weekText = useFormatMessage('dashboard.calendar.thisWeek', 'This Week')
  const monthText = useFormatMessage('dashboard.calendar.thisMonth', 'This Month')
  const yearText = useFormatMessage('dashboard.calendar.thisYear', 'This Year')
  const previousQuarterText = useFormatMessage('dashboard.calendar.previousQuarter', 'Previous Quarter')
  const reportsText = useFormatMessage('dashboard.report.heading', 'Reports')
  const searchText = useFormatMessage('dashboard.text.search', 'Search')
  const financialSummaryReportTitle = useFormatMessage(
    'dashboard.reports.modal.title.financialSummaryReportTitle',
    'Financial Summary Report',
  )
  const financialSummaryReportBody = useFormatMessage(
    'dashboard.reports.modal.body.financialSummaryReportBody',
    'Highlights all charging transactions and provides a breakdown of the total amount paid by drivers, fees collected by SWTCH, and remittance amounts.',
  )
  const utilityChargeSessionReportTitle = useFormatMessage(
    'dashboard.reports.modal.title.utilityChargeSessionReportTitle',
    'Utility Charge Session Report',
  )
  const utilityChargeSessionReportBody = useFormatMessage(
    'dashboard.reports.modal.body.utilityChargeSessionReportBody',
    'Provides insight into charging session specifics, including energy consumption, session details, and more.',
  )
  const utilityIntervalReportTitle = useFormatMessage(
    'dashboard.reports.modal.title.utilityIntervalReportTitle',
    'Utility Interval Report',
  )
  const utilityIntervalReportBody = useFormatMessage(
    'dashboard.reports.modal.body.utilityIntervalReportBody',
    'A frequently requested report by utility programs, offering transaction breakdowns at 15-minute intervals. Includes data on energy consumption and peak/average power for comprehensive analysis of charging sessions.',
  )

  const siteReportTitle = useFormatMessage('dashboard.reports.modal.title.siteReport', 'Site Report')
  const siteReportBody = useFormatMessage(
    'dashboard.reports.modal.body.siteReport',
    'Includes details on charger’s activation date, current and previous operational status, and charger specific details.',
  )

  const chargerUtilizationReportTitle = useFormatMessage(
    'dashboard.reports.modal.title.chargerUtilizationReport',
    'Charger Utilization Report',
  )
  const chargerUtilizationReportBody = useFormatMessage(
    'dashboard.reports.modal.body.chargerUtilizationReport',
    'Highlights charger utilization, including the number of charging sessions, energy consumption, and more.',
  )

  const uptimeReportTitle = useFormatMessage('dashboard.reports.modal.title.uptimeReport', 'Uptime Report')
  const uptimeReportBody = useFormatMessage('dashboard.reports.modal.body.uptimeReport', 'Uptime Report')
  const reportOverviewText = useFormatMessage('dashboard.text.reportOverview', 'Report Overview')
  const listingTitleDescription = useFormatMessage(
    'dashboard.text.listingTitle.description',
    'listing title for financial report',
  )
  const transactionIdDescription = useFormatMessage('dashboard.text.transactionId.description', 'transaction id')
  const siteDescription = useFormatMessage('dashboard.text.site.description', 'site')
  const countryDescription = useFormatMessage('dashboard.text.country.description', 'country')
  const stateDescription = useFormatMessage('dashboard.text.state.description', 'state')
  const stateProvinceHeading = useFormatMessage('dashboard.text.stateProvince.heading', 'State/Province')
  const transactionStateDescription = useFormatMessage(
    'dashboard.text.transactionState.description',
    'transaction state',
  )
  const startTimeLocalDescription = useFormatMessage('dashboard.text.startTimeLocal.description', 'start time local')
  const endTimeLocalDescription = useFormatMessage('dashboard.text.endTimeLocal.description', 'end time local')
  const startTimeLocalHeading = useFormatMessage('dashboard.text.startTimeLocal.heading', 'Start Time Local')
  const endTimeLocalHeading = useFormatMessage('dashboard.text.endTimeLocal.heading', 'End Time Local')
  const chargingDurationInHoursDescription = useFormatMessage(
    'dashboard.text.chargingDurationInHours.description',
    'charging duration in hours',
  )
  const chargingDurationInHoursHeading = useFormatMessage(
    'dashboard.text.chargingDurationInHours.heading',
    'Charging Duration (Hours)',
  )

  const energyInkWhDescription = useFormatMessage(
    'dashboard.text.energyInkWh.description',
    'The total amount of energy consumed during a charging session, measured in kilowatt-hours (kWh)',
  )
  const energyInkWhHeading = useFormatMessage('dashboard.text.energyInkWh.heading', 'Energy (kWh)')

  const ghgSavingDescription = useFormatMessage('dashboard.text.ghgSaving.description', 'GHG Saving')
  const ghgSavingHeading = useFormatMessage('dashboard.text.ghgSaving.heading', 'GHG Saving')
  const driverFeeHeading = useFormatMessage('dashboard.text.driverFee.heading', 'Driver Fee')
  const driverFeeDescription = useFormatMessage(
    'dashboard.text.driverFee.description',
    'The amount paid by the driver for energy consumption',
  )
  const driverPaymentProcessingFeeDescription = useFormatMessage(
    'dashboard.text.driverPaymentProcessingFee.description',
    'Driver Payment Processing Fee',
  )
  const driverPaymentProcessingFeeHeading = useFormatMessage(
    'dashboard.text.driverPaymentProcessingFee.heading',
    'Driver Payment Processing Fee',
  )
  const driverTaxPaidDescription = useFormatMessage(
    'dashboard.text.driverTaxPaid.description',
    'The total tax amount on a driver transaction based on the geography of the charger and local tax regulations',
  )
  const driverTaxPaidHeading = useFormatMessage('dashboard.text.driverTaxPaid.heading', 'Driver Tax Paid')
  const driverTotalPaidDescription = useFormatMessage(
    'dashboard.text.driverTotalPaid.description',
    'The total amount paid by the driver for the transaction. Sum of Driver Fee + Driver Payment Processing Fee + Driver Tax Paid',
  )
  const driverTotalPaidHeading = useFormatMessage('dashboard.text.driverTotalPaid.heading', 'Driver Total Paid')
  const ownerTransactionFeeHeading = useFormatMessage(
    'dashboard.text.ownerTransactionFee.heading',
    'Owner Transaction Fee',
  )
  const ownerTransactionFeeDescription = useFormatMessage(
    'dashboard.text.ownerTransactionFee.description',
    'Owner Transaction Fee',
  )

  const ownerPaymentProcessingFeeHeading = useFormatMessage(
    'dashboard.text.ownerPaymentProcessingFee.heading',
    'Owner Payment Processing Fee',
  )
  const ownerPaymentProcessingFeeDescription = useFormatMessage(
    'dashboard.text.ownerPaymentProcessingFee.description',
    'Owner Payment Processing Fee',
  )

  const ownerTaxHeading = useFormatMessage('dashboard.text.ownerTax.heading', 'Owner Tax')
  const ownerTaxDescription = useFormatMessage('dashboard.text.ownerTax.description', 'Owner Tax')

  const propertySubTotalHeading = useFormatMessage('dashboard.text.propertySubtotal.heading', 'Property Sub-total')
  const propertySubTotalDescription = useFormatMessage(
    'dashboard.text.propertySubtotal.description',
    'The net earnings of the property for each transaction after subtracting applicable tax. This is the amount that will be paid at quarterly remittance if a site is NOT registered for tax',
  )

  const propertyTaxHeading = useFormatMessage('dashboard.text.propertyTax.heading', 'Property Tax')
  const propertyTaxDescription = useFormatMessage('dashboard.text.propertyTax.description', 'Property Tax')

  const propertyTotalHeading = useFormatMessage('dashboard.text.propertyTotal.heading', 'Property Total')
  const propertyTotalDescription = useFormatMessage('dashboard.text.propertyTotal.description', 'Property Total')

  const connectorIdDescription = useFormatMessage('dashboard.text.connectorId.description', 'Connector ID')

  const addressHeading = useFormatMessage('dashboard.text.address.heading', 'Address')
  const addressDescription = useFormatMessage('dashboard.text.address.description', 'Address')
  const cityDescription = useFormatMessage('dashboard.text.city.description', 'City')

  const sessionStartDateHeading = useFormatMessage('dashboard.text.sessionStartDate.heading', 'Session Start Date')
  const sessionStartDateDescription = useFormatMessage(
    'dashboard.text.sessionStartDate.description',
    'The date and time the charging session started',
  )

  const sessionEndDateHeading = useFormatMessage('dashboard.text.sessionEndDate.heading', 'Session End Date')
  const sessionEndDateDescription = useFormatMessage(
    'dashboard.text.sessionEndDate.description',
    'The date and time the charging session ended',
  )

  const sessionStartTimeHeading = useFormatMessage('dashboard.text.sessionStartTime.heading', 'Session Start Time')
  const sessionStartTimeDescription = useFormatMessage(
    'dashboard.text.sessionStartTime.description',
    'The time the charging session started',
  )
  const sessionEndTimeHeading = useFormatMessage('dashboard.text.sessionEndTime.heading', 'Session End Time')
  const sessionEndTimeDescription = useFormatMessage(
    'dashboard.text.sessionEndTime.description',
    'The time the charging session ended',
  )

  const chargingStartDateHeading = useFormatMessage('dashboard.text.chargingStartDate.heading', 'Charging Start Date')
  const chargingStartDateDescription = useFormatMessage(
    'dashboard.text.chargingStartDate.description',
    'Charging Start Date',
  )

  const chargingStartTimeHeading = useFormatMessage('dashboard.text.chargingStartTime.heading', 'Charging Start Time')
  const chargingStartTimeDescription = useFormatMessage(
    'dashboard.text.chargingStartTime.description',
    'The time the charging session started',
  )

  const chargingEndDateHeading = useFormatMessage('dashboard.text.chargingEndDate.heading', 'Charging End Date')
  const chargingEndDateDescription = useFormatMessage(
    'dashboard.text.chargingEndDate.description',
    'The date and time the charging session ended',
  )

  const chargingEndTimeHeading = useFormatMessage('dashboard.text.chargingEndTime.heading', 'Charging End Time')
  const chargingEndTimeDescription = useFormatMessage(
    'dashboard.text.chargingEndTime.description',
    'The time the charging session ended',
  )
  const timeHeading = useFormatMessage('dashboard.text.timezone.heading', 'Timezone')
  const timeDescription = useFormatMessage('dashboard.text.timezone.description', 'Timezone')

  const chargingDurationHeading = useFormatMessage('dashboard.text.chargingDuration.heading', 'Charging Duration')
  const chargingDurationDescription = useFormatMessage(
    'dashboard.text.chargingDuration.description',
    'Charging Duration',
  )

  const sessionDurationHeading = useFormatMessage('dashboard.text.sessionDuration.heading', 'Session Duration')
  const sessionDurationDescription = useFormatMessage('dashboard.text.sessionDuration.description', 'Session Duration')

  const loiteringDurationHeading = useFormatMessage('dashboard.text.loiteringDuration.heading', 'Loitering Duration')
  const loiteringDurationDescription = useFormatMessage(
    'dashboard.text.loiteringDuration.description',
    'Loitering Duration',
  )
  const idlingDurationHeading = useFormatMessage('dashboard.text.idlingDuration.heading', 'Idling Duration')
  const idlingDurationDescription = useFormatMessage('dashboard.text.idlingDuration.description', 'Idling Duration')

  const totalEnergyProvidedHeading = useFormatMessage(
    'dashboard.text.totalEnergyProvided.heading',
    'Total Energy Provided',
  )
  const totalEnergyProvidedDescription = useFormatMessage(
    'dashboard.text.totalEnergyProvided.description',
    'Total Energy Provided',
  )

  const averagePowerHeading = useFormatMessage('dashboard.text.averagePower.heading', 'Average Power')
  const averagePowerDescription = useFormatMessage('dashboard.text.averagePower.description', 'Average Power')

  const peakPowerHeading = useFormatMessage('dashboard.text.peakPower.heading', 'Peak Power')
  const peakPowerDescription = useFormatMessage('dashboard.text.peakPower.description', 'Peak Power')
  const chargerLifetimeEnergyDeliveredHeading = useFormatMessage(
    'dashboard.text.chargerLifetimeEnergyDelivered.heading',
    'Charger Lifetime Energy Delivered',
  )
  const chargerLifetimeEnergyDeliveredDescription = useFormatMessage(
    'dashboard.text.chargerLifetimeEnergyDelivered.description',
    'Charger Lifetime Energy Delivered',
  )

  const chargerMaxOutputHeading = useFormatMessage('dashboard.text.chargerMaxOutput.heading', 'Charger Max Output')
  const chargerMaxOutputDescription = useFormatMessage(
    'dashboard.text.chargerMaxOutput.description',
    'Charger Max Output',
  )

  const evseVendorHeading = useFormatMessage('dashboard.text.evseVendor.heading', 'EVSE Vendor')
  const evseVendorDescription = useFormatMessage('dashboard.text.evseVendor.description', 'EVSE Vendor')
  const evseModelHeading = useFormatMessage('dashboard.text.evseModel.heading', 'EVSE Model')
  const evseModelDescription = useFormatMessage('dashboard.text.evseModel.description', 'EVSE Model')
  const evseSerialNumberHeading = useFormatMessage('dashboard.text.evseSerialNumber.heading', 'EVSE Serial Number')
  const evseSerialNumberDescription = useFormatMessage(
    'dashboard.text.evseSerialNumber.description',
    'EVSE Serial Number',
  )

  const connectorTypeHeading = useFormatMessage('dashboard.text.connectorType.heading', 'Connector Type')
  const connectorTypeDescription = useFormatMessage('dashboard.text.connectorType.description', 'Connector Type')
  const chargerTypeHeading = useFormatMessage('dashboard.text.chargerType.heading', 'Charger Type')
  const chargerTypeDescription = useFormatMessage('dashboard.text.chargerType.description', 'Charger Type')

  const serviceTypeCodeHeading = useFormatMessage('dashboard.text.serviceTypeCode.heading', 'Service Type Code')
  const serviceTypeCodeDescription = useFormatMessage('dashboard.text.serviceTypeCode.description', 'Service Type Code')

  const priceTypeHeading = useFormatMessage('dashboard.text.priceType.heading', 'Price Type')
  const priceTypeDescription = useFormatMessage('dashboard.text.priceType.description', 'Price Type')
  const transactionStatuHeading = useFormatMessage('dashboard.text.transactionStatu.heading', 'Transaction Status')
  const transactionStatuDescription = useFormatMessage(
    'dashboard.text.transactionStatu.description',
    'Transaction Status',
  )

  const vehicleMakeHeading = useFormatMessage('dashboard.text.vehicleMake.heading', 'Vehicle Make')
  const vehicleMakeDescription = useFormatMessage('dashboard.text.vehicleMake.description', 'Vehicle Make')
  const vehicleModelHeading = useFormatMessage('dashboard.text.vehicleModel.heading', 'Vehicle Model')
  const vehicleModelDescription = useFormatMessage('dashboard.text.vehicleModel.description', 'Vehicle Model')
  const ratedOutputHeading = useFormatMessage('dashboard.text.ratedOutput.heading', 'Rated Output (kW)')
  const ratedOutputDescription = useFormatMessage('dashboard.text.ratedOutput.description', 'Rated Output (kW)')
  const locationSettingHeading = useFormatMessage('dashboard.text.locationSetting.heading', 'Location Setting')
  const locationSettingDescription = useFormatMessage('dashboard.text.locationSetting.description', 'Location Setting')
  const evseStatusHeading = useFormatMessage('dashboard.text.evseStatus.heading', 'EVSE Status')
  const evseStatusDescription = useFormatMessage('dashboard.text.evseStatus.description', 'EVSE Status')
  const effectiveStatusDateHeading = useFormatMessage(
    'dashboard.text.effectiveStatusDate.heading',
    'Effective Status Date',
  )
  const effectiveStatusDateDescription = useFormatMessage(
    'dashboard.text.effectiveStatusDate.description',
    'Effective Status Date',
  )
  const effectiveStatusTimeHeading = useFormatMessage(
    'dashboard.text.effectiveStatusTime.heading',
    'Effective Status Time',
  )
  const effectiveStatusTimeDescription = useFormatMessage(
    'dashboard.text.effectiveStatusTime.description',
    'Effective Status Time',
  )
  const previousStatusHeading = useFormatMessage('dashboard.text.previousStatus.heading', 'Previous Status')
  const previousStatusDescription = useFormatMessage('dashboard.text.previousStatus.description', 'Previous Status')
  const previousStatusDateHeading = useFormatMessage(
    'dashboard.text.previousStatusDate.heading',
    'Previous Status Date',
  )
  const previousStatusDateDescription = useFormatMessage(
    'dashboard.text.previousStatusDate.description',
    'Previous Status Date',
  )
  const previousStatusTimeHeading = useFormatMessage(
    'dashboard.text.previousStatusTime.heading',
    'Previous Status Time',
  )
  const previousStatusTimeDescription = useFormatMessage(
    'dashboard.text.previousStatusTime.description',
    'Previous Status Time',
  )
  const activationDateHeading = useFormatMessage('dashboard.text.activationDate.heading', 'Activation Date')
  const activationDateDescription = useFormatMessage('dashboard.text.activationDate.description', 'Activation Date')

  const siteIdHeading = useFormatMessage('dashboard.text.siteId.heading', 'Site ID')
  const siteIdDescription = useFormatMessage('dashboard.text.siteId.description', 'Site ID')

  const intervalIdHeading = useFormatMessage('dashboard.text.intervalId.heading', 'Interval ID')
  const intervalIdDescription = useFormatMessage('dashboard.text.intervalId.description', 'Interval ID')
  const portNumberHeading = useFormatMessage('dashboard.text.portNumber.heading', 'Port Number')
  const portNumberDescription = useFormatMessage('dashboard.text.portNumber.description', 'Port Number')
  const intervalStartDateHeading = useFormatMessage('dashboard.text.intervalStartDate.heading', 'Interval Start Date')
  const intervalStartDateDescription = useFormatMessage(
    'dashboard.text.intervalStartDate.description',
    'Interval Start Date',
  )
  const intervalStartTimeHeading = useFormatMessage('dashboard.text.intervalStartTime.heading', 'Interval Start Time')
  const intervalStartTimeDescription = useFormatMessage(
    'dashboard.text.intervalStartTime.description',
    'Interval Start Time',
  )
  const intervalEndDateHeading = useFormatMessage('dashboard.text.intervalEndDate.heading', 'Interval End Date')
  const intervalEndDateDescription = useFormatMessage('dashboard.text.intervalEndDate.description', 'Interval End Date')
  const intervalEndTimeHeading = useFormatMessage('dashboard.text.intervalEndTime.heading', 'Interval End Time')
  const intervalEndTimeDescription = useFormatMessage('dashboard.text.intervalEndTime.description', 'Interval End Time')
  const energyDeliveredHeading = useFormatMessage('dashboard.text.energyDelivered.heading', 'Energy Delivered')
  const energyDeliveredDescription = useFormatMessage('dashboard.text.energyDelivered.description', 'Energy Delivered')
  const peakDemandHeading = useFormatMessage('dashboard.text.peakDemand.heading', 'Peak Demand')
  const peakDemandDescription = useFormatMessage('dashboard.text.peakDemand.description', 'Peak Demand')
  const averageDemandHeading = useFormatMessage('dashboard.text.averageDemand.heading', 'Average Demand')
  const averageDemandDescription = useFormatMessage('dashboard.text.averageDemand.description', 'Average Demand')
  const serialNumberDescription = useFormatMessage('dashboard.text.serialNumber.description', 'Serial Number')
  const utilizationPercentHeading = useFormatMessage('dashboard.text.utilizationPercent.heading', 'Utilization Percent')
  const utilizationPercentDescription = useFormatMessage(
    'dashboard.text.utilizationPercent.description',
    'Utilization Percent',
  )
  const totalTimeInUseMinutesHeading = useFormatMessage(
    'dashboard.text.totalTimeInUseMinutes.heading',
    'Total Time In Use (Min)',
  )
  const totalInUseMinutesDescription = useFormatMessage(
    'dashboard.text.totalTimeInUseMinutes.description',
    'Total Time In Use (Min)',
  )
  const totalTimeInPeriodMinutesHeading = useFormatMessage(
    'dashboard.text.totalTimeInPeriodMinutes.heading',
    'Total Time In Period (Min)',
  )
  const totalTimeInPeriodMinutesDescription = useFormatMessage(
    'dashboard.text.totalTimeInPeriodMinutes.description',
    'Total Time In Period (Min)',
  )

  const uptimePercentageHeading = useFormatMessage('dashboard.text.uptimePercentage.heading', 'Uptime Percentage')
  const uptimePercentageDescription = useFormatMessage(
    'dashboard.text.uptimePercentage.description',
    'Uptime Percentage',
  )
  const numberOfOutagesHeading = useFormatMessage('dashboard.text.numberOfOutages.heading', 'Number of Outages')
  const numberOfOutagesDescription = useFormatMessage('dashboard.text.numberOfOutages.description', 'Number of Outages')
  const totalOutageTimeHeading = useFormatMessage('dashboard.text.totalOutageTime.heading', 'Total Outage Time')
  const totalOutageTimeDescription = useFormatMessage('dashboard.text.totalOutageTime.description', 'Total Outage Time')
  const totalExcludingTimeHeading = useFormatMessage('dashboard.text.totalExcludedTime.heading', 'Total Excluded Time')
  const totalExcludingTimeDescription = useFormatMessage(
    'dashboard.text.totalExcludedTime.description',
    'Total Excluded Time',
  )

  const uptimePercentageDisclaimerTitle = useFormatMessage(
    'dashboard.text.uptimePercentage.disclaimer.title',
    'Uptime Report Disclaimer',
  )
  const uptimePercentageDisclaimerDescription = useFormatMessage(
    'dashboard.text.uptimePercentage.disclaimer.description',
    'Please note that the number of outages shown here reflects all recorded downtime events. However, detailed incident data is only available once chargers return to operational status. As a result, the number of described incidents on the Incidents page may not always match the total outages displayed in this report. Additionally, chargers currently in an “Under Repair” status are now included in the uptime percentage calculation, which may impact reported uptime figures.',
  )
  return {
    activationDateHeading,
    activationDateDescription,
    addressHeading,
    addressDescription,
    averagePowerDescription,
    averagePowerHeading,
    averageDemandHeading,
    averageDemandDescription,
    buttonTitle,
    erroredTrxsBtnTitle,
    trxText,
    erroredTrxText,
    todayText,
    weekText,
    monthText,
    yearText,
    previousQuarterText,
    reportsText,
    reportOverviewText,
    searchText,
    chargerUtilizationReportTitle,
    chargerUtilizationReportBody,
    chargingDurationInHoursDescription,
    chargingDurationInHoursHeading,
    chargingStartTimeHeading,
    chargingStartDateHeading,
    chargingStartDateDescription,
    chargingStartTimeDescription,
    chargingEndDateHeading,
    chargingEndDateDescription,
    chargingEndTimeHeading,
    chargingEndTimeDescription,
    chargingDurationHeading,
    chargingDurationDescription,
    chargerLifetimeEnergyDeliveredHeading,
    chargerLifetimeEnergyDeliveredDescription,
    chargerMaxOutputHeading,
    chargerMaxOutputDescription,
    chargerTypeHeading,
    chargerTypeDescription,
    connectorTypeHeading,
    connectorTypeDescription,
    peakPowerHeading,
    peakPowerDescription,
    cityDescription,
    countryDescription,
    connectorIdDescription,
    driverFeeDescription,
    driverFeeHeading,
    driverPaymentProcessingFeeDescription,
    driverPaymentProcessingFeeHeading,
    driverTaxPaidDescription,
    driverTaxPaidHeading,
    driverTotalPaidDescription,
    driverTotalPaidHeading,
    effectiveStatusDateHeading,
    effectiveStatusDateDescription,
    effectiveStatusTimeHeading,
    effectiveStatusTimeDescription,
    energyDeliveredHeading,
    energyDeliveredDescription,
    energyInkWhDescription,
    energyInkWhHeading,
    endTimeLocalDescription,
    endTimeLocalHeading,
    evseVendorHeading,
    evseVendorDescription,
    evseModelHeading,
    evseModelDescription,
    evseStatusHeading,
    evseStatusDescription,
    evseSerialNumberHeading,
    evseSerialNumberDescription,
    financialSummaryReportTitle,
    financialSummaryReportBody,
    ghgSavingDescription,
    ghgSavingHeading,
    idlingDurationHeading,
    idlingDurationDescription,
    intervalIdHeading,
    intervalIdDescription,
    intervalStartDateHeading,
    intervalStartDateDescription,
    intervalStartTimeHeading,
    intervalStartTimeDescription,
    intervalEndDateHeading,
    intervalEndDateDescription,
    intervalEndTimeHeading,
    intervalEndTimeDescription,
    listingTitleDescription,
    locationSettingHeading,
    locationSettingDescription,
    loiteringDurationDescription,
    loiteringDurationHeading,
    ownerTransactionFeeDescription,
    ownerTransactionFeeHeading,
    ownerPaymentProcessingFeeDescription,
    ownerPaymentProcessingFeeHeading,
    ownerTaxDescription,
    ownerTaxHeading,
    numberOfOutagesHeading,
    numberOfOutagesDescription,
    portNumberHeading,
    portNumberDescription,
    peakDemandHeading,
    peakDemandDescription,
    priceTypeHeading,
    priceTypeDescription,
    propertySubTotalHeading,
    propertySubTotalDescription,
    propertyTaxHeading,
    propertyTaxDescription,
    propertyTotalHeading,
    propertyTotalDescription,
    previousStatusHeading,
    previousStatusDescription,
    previousStatusDateHeading,
    previousStatusDateDescription,
    previousStatusTimeHeading,
    previousStatusTimeDescription,
    ratedOutputHeading,
    ratedOutputDescription,
    serviceTypeCodeHeading,
    serviceTypeCodeDescription,
    serialNumberDescription,
    sessionDurationHeading,
    sessionDurationDescription,
    sessionStartDateDescription,
    sessionStartDateHeading,
    sessionEndDateDescription,
    sessionEndDateHeading,
    sessionStartTimeDescription,
    sessionStartTimeHeading,
    sessionEndTimeDescription,
    sessionEndTimeHeading,
    startTimeLocalHeading,
    startTimeLocalDescription,
    stateDescription,
    stateProvinceHeading,
    siteIdHeading,
    siteIdDescription,
    siteReportTitle,
    siteReportBody,
    siteDescription,
    timeHeading,
    timeDescription,
    totalEnergyProvidedHeading,
    totalEnergyProvidedDescription,
    totalTimeInUseMinutesHeading,
    totalInUseMinutesDescription,
    totalTimeInPeriodMinutesHeading,
    totalTimeInPeriodMinutesDescription,
    totalOutageTimeHeading,
    totalOutageTimeDescription,
    totalExcludingTimeHeading,
    totalExcludingTimeDescription,
    transactionIdDescription,
    transactionStateDescription,
    transactionStatuHeading,
    transactionStatuDescription,
    uptimePercentageHeading,
    uptimePercentageDescription,
    utilityChargeSessionReportTitle,
    utilityChargeSessionReportBody,
    utilityIntervalReportTitle,
    utilityIntervalReportBody,
    utilizationPercentHeading,
    utilizationPercentDescription,
    uptimeReportTitle,
    uptimeReportBody,
    vehicleMakeHeading,
    vehicleMakeDescription,
    vehicleModelHeading,
    vehicleModelDescription,
    uptimePercentageDisclaimerTitle,
    uptimePercentageDisclaimerDescription,
  }
}
